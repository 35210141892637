header {
    border-top: 0px;
}
.header-data-pc {
    display: none;
}
.header-data-mobile {
	display: block;
}
.header-data-mobile .site-info {
	margin-bottom: 0px;
}
.site-logo, .school-name {
	display: table-cell;
	
}
.site-logo img {
	margin: 0px;
	max-height: 55px;
	padding-right: 18px;
	width: auto;
}

.header-data-mobile .content {
    border-bottom: 0px;
}
.header-data-pc .site-header-content, .header-data-mobile .site-header-content {
	max-width: inherit;
}
.header-data-pc .school-name, .header-data-mobile .school-name {
	border-left: 1px solid #003366;
    font-size: 18px;
	padding-left: 18px;
}
.h-topright {
    padding: 22px 0 4px;
}
.site-profile {
	display: table;
    padding: 18px 18px;
}
.site-header-content {
	padding: 0px;
}
.header-data-pc .site-header-content .search-font, .header-data-mobile .site-header-content .search-font {
	margin-left: 0px;
}
.header-data-pc .school-name, .header-data-mobile .school-name, .header-data-pc .department-name, .header-data-mobile .department-name, .header-data-pc .school-name a, .header-data-mobile .school-name a, .header-data-pc .department-name a, .header-data-mobile .department-name a {
    color: #003366;
}
.header-data-mobile .site-header-content .drawer-toggle {
	height: auto;
	padding: 2px 24px;
}
div.search-font {
	display: none;
}
.drawer {
	background: white;
}

footer {
	margin-top: 0px;
}
footer.footer {
	display: none;
}
.footer-top {
	display: none;
}
.footer .content-locator .social-share-col {
    padding-right: 0px;
    padding-top: 15px;	
}
.copyright {
	max-width: 370px;
}
div.branding div.wrapper {
	min-height: auto!important;
}
img#lib_logo {
        max-height: 55px;
}
@media screen and (max-width: 3000px) {
	.header-data-pc .site-logo a, .header-data-mobile .site-logo {
		padding-right: 0;
	}
}
@media screen and (min-width: 1023px) {
        #hkust_footer_logo {
                max-width: 150px;
        }
}
@media (min-width: 768px) {
	footer .container {
		padding-left: 0;
		padding-right: 0;
	}
}
@media screen and (max-width: 1023px) {
        #hkust_footer_logo {
                max-height: 27px;
                max-width: 84px;
        }
	.footer {
		padding: 0 20px 0px;
	}
	.copyright {
		max-width: unset;
		padding-bottom: 9px;
	}
}
@media (max-width: 767px) {
	.search-font > i.fa-search {
		display: inline;
	}
	.logo {
		padding: 0;
	}
	.header-data-pc .site-profile, .header-data-mobile .site-profile {
		padding: 13px 13px;
	}
	.header-data-pc .site-logo img, .header-data-mobile .site-logo img {
		padding-right: 13px;
	}
	.header-data-pc .school-name, .header-data-mobile .school-name {
		font-size: 15px;
		padding-left: 13px;
	}
	.h-topright {
		padding: 0;
	}
	.mobileSearch-container, .mobileMenu-trigger {
		float: right;
		position: relative;
		padding: 0;
		margin-right: 2px;
		text-align: center;
		top: 15px;
	}
	.mobileMenu-trigger {
		margin-right: -34px;
	}
	.mobileSearch-container .active .search_dropdown, 
	.mobileSearch-container .input-focus .search_dropdown {
		border: 1px solid #003366;
		left: -146px;
		position: relative;
		top: 52px;
		width: 240px;
	}
	.mobileSearch-container .active #search-button {
		position: relative;
		top: -46px;
	}
}

@media (max-width: 400px) {
	.logo {
		min-height: auto;
	}
	.logo img {
		height: 27px !important;
	}
	.header-data-pc .school-name, .header-data-mobile .school-name {
		font-size: 10px;
		padding-left: 9px;
	}
	.header-data-pc .site-logo img, .header-data-mobile .site-logo img {
		padding-right: 9px;
	}
	.header-data-pc .site-profile, .header-data-mobile .site-profile {
		padding: 9px 9px;
	}
	.mobileSearch-container, .mobileMenu-trigger {
		top: 6px;
	}
	.mobileSearch-container .active .search_dropdown {
		left: -83px;
		top: 39px;
		width: 178px;
	}
}
