/** Help Variables **/
/* Fonts */
/* Images */
/** Bootstrap Variables **/
/* Colors */
/* Reassign color vars to semantic color scheme */
/* Fonts */
.ds-full-screen-loader {
  height: 100vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}